<template>
  <div class="goods-detail-scroll">
    <div class="goods-detail">
      <!-- 轮播图 -->
      <banner :banners="banners" v-model="detail.is_collect" style="z-index:0"></banner>
      <flash-sale
        :goodsType="goodsType"
        :detail="detail"
        :goodsPrice="goodsPrice"
        :businessType="businessType"
        :showWay="showWay"
        :integralRate="integralRate"
        v-if="goodsType == 1 || goodsType == 2"
      ></flash-sale>
      <div class="goods-info">
        <div class="goods-intro">
          <p class="goods-name ft-weight">{{detail.item_name}}</p>
          <p class="goods-tip">{{detail.item_desc}}</p>
        </div>
      </div>
      <!-- 好友拼团列表 -->
      <div class="group-content" v-if="goodsType == 3 && groupList.length > 0">
        <group-list
          :moreGroup="true"
          :groupList="groupList"
          :group_buy_item_id="detail.group_buy_item_info.id"
        ></group-list>
      </div>
      <!-- 评价列表 -->
      <div class="evaluate-content" v-if="evaluateTotal > 0">
        <div class="evaluate-title" @click="moreEvaluate()">
          <div class="tip">商品评价（{{evaluateTotal}}）</div>
          <div class="look-more">
            <span>查看全部</span>
            <img src="~@/assets/icon_more_light@2x.png" alt />
          </div>
        </div>
        <evaluate-list :list="evaluateList"></evaluate-list>
      </div>
      <!-- 商品详情 富文本 -->
      <div class="goods-html">
        <div class="content-title">
          <span class="dian"></span>
          <span class="line"></span>
          <span class="text">商品详情</span>
          <span class="line"></span>
          <span class="dian"></span>
        </div>
      </div>
      <div class="contents" v-html="item_txt.contents"></div>
    </div>
  </div>
</template>
<script>
import banner from "@/components/goods/banner";
import evaluateList from "@/components/goods/evaluateList";
import flashSale from "@/components/goods/flashSale";
import groupList from "@/components/goods/groupList";
import groupPlay from "@/components/goods/groupPlay";
import sku from "@/components/goods/sku";
import selectAddress from "@/components/common/selectAddress";
import {
  goodsDetail,
  getGroupList,
  goodsEvaluate,
  checkGoods,
  collectGoods
} from "@/services/goodsApi.js";
import {
  getGoodsType,
  getGoodsPrice,
  getBanners,
  SERVE_ITEMS,
  GROUP_RULES,
  getSales
} from "@/utils/goodsUtil.js";
import "@/utils/qiyu";
import { baseUrl } from "../../utils/env";
import { getStore , wxShare}	from "../../utils/common";
import { mapState } from "vuex";
import { ServicePhone } from "@/services";

export default {
  name: "cmGoodsDetail",
  data() {
    return {
      item_id: this.$route.query.item_id,
      loadding: false, // 是否加载完成
      detail: {}, // 商品详情
      item_txt: "", //商品详情富文本
      goodsPrice: {}, // 商品相关价格
      buyType: 0, // 购买类型， 0：左边按钮，1：右边按钮 eg: 0，加入购物车， 1 立即购买
      banners: [],
      serveItems: SERVE_ITEMS,
      show: false,
      groupRules: GROUP_RULES,
      dialog: false,
      goodsType: 0, // 商品类型判断  0： 普通商品，  1： 限时购商品在售状态   2： 限时购商品预售状态   3： 团购商品
      skuList: [], // 所有的sku属性
      skuInfo: [], // 所有sku商品
      groupList: [], // 团购商品的开团成员列表
      activedAddress: {}, //选择地址
      evaluateList: [], // 评价列表
      evaluateTotal: 0,
      edit_date: "", //默认显示的收货地址
      max_integral: "", //积分使用比例
      is_collect: 0, // 是否收藏
      ServiceMobile: "",
      ServiceStatus: "",
      sermob: false //弹框
    };
  },

  computed: {
    goodsInfo() {
      return this.$store.state.goodsInfo;
    },
    ...mapState({
	    bid: state => state.businessInfo.id,		//商城id
      default_address: state => state.default_address,   //默认地址信息
      businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      showWay: state => state.config.show_way, // 商品展示方式，1积分，2现金
      price_status: state => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: state => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: state => state.config.integral_rate // 积分比例
    })
  },
  components: {
    banner,
    evaluateList,
    flashSale,
    groupList,
    groupPlay,
    sku,
    selectAddress
  },
  methods: {
    chooseAddress() {
      this.$refs.address.choseAdd();
    },
    confirmAddress(params) {
      this.activedAddress = params;
      this.$store.commit("setAddress", params);
      this.checkGood(params);
    },
    // 商品详情选择地址判断邮费（第三方需要判断是否有货）
    async checkGood(params) {
      try {
        let address_info = params;
        let data = {
          item_id: this.item_id,
          province_id: address_info.province_id,
          city_id: address_info.city_id,
          area_id: address_info.area_id,
          town_id: address_info.street_id
        };
        let checkData = await checkGoods(data);
        if (checkData.code === 0) {
          this.$store.commit("setGoodsInfo", {
            postage: checkData.data.postage,
            stock_name: checkData.data.stock_name
          });
        } else {
          this.$dialog.toast({
            mes: checkData.msg,
            timeout: 1500,
            icon: "none",
            callback: () => {
              this.closeDialog();
            }
          });
        }
      } catch (err) {
        throw err;
      }
    },
    //路由改变jssdk参数重新赋值
    beforeRouteEnter(to, from, next) {
      next();
      wxShare();
    },
    shoppingCar() {
      this.$router.push({
        path: "/shopping_cart"
      });
    },
    //收藏
    async collectGoods() {
      try {
        let data = await collectGoods(this.$route.query.item_id);
        if (data.code === 0) {
          this.is_collect = this.is_collect == 1 ? 0 : 1;
        }
      } catch (err) {
        throw err;
      }
    },
    showDialog(type) {
      if (
        !this.$store.state.activedAddress.province_id &&
        this.detail.source_type != 6
      ) {
        this.chooseAddress();
        this.$dialog.toast({
          mes: "请先选择配送区域",
          timeout: 1000
        });
      } else {
        this.buyType = type;
        this.dialog = true;
      }
    },
    // 获取团购商品的开团用户列表
    async getGroupList() {
      try {
        let group_buy_item_id = this.detail.group_buy_item_info.id;
        let data = await getGroupList(group_buy_item_id, 0, 3);
        if (data.code === 0) {
          this.groupList = data.data.data;
        } else {
          this.errDialog(data);
        }
      } catch (err) {
        throw err;
      }
    },
    // 进行商品详情的相关赋值
    initGoodsDetail(data) {
      this.item_txt = data.item_txt; //商品详情富文本
      this.is_collect = data.is_collect; //是否收藏
      this.max_integral = Number.parseFloat(data.max_integral) / 100;
      this.goodsType = getGoodsType(data);
      this.goodsPrice = getGoodsPrice(data, this.goodsType);
      this.detail = {...data, ...this.goodsPrice};
      this.banners = getBanners(data.photo_list);
      if (this.goodsType === 3) {
        this.getGroupList();
      }
      if (this.detail.has_sku == 1) {
        this.skuList = this.detail.sku_list;
        this.skuInfo = this.detail.sku_info;
      } else {
        this.skuInfo.push({
          item_id: this.item_id,
          price: this.goodsPrice.real_price,
          del_price: this.goodsPrice.del_price,
          status: 1,
          stock: this.detail.stock
        });
      }
      this.loadding = true;
    },
    async getGoodsDetail() {
      try {
        let data = await goodsDetail(this.item_id);
        if (data.code === 0) {
          this.initGoodsDetail(data.data);
        } else {
          this.errDialog(data);
        }
      } catch (err) {
        throw err;
      }
    },
    // 报错弹窗
    errDialog(data) {
      this.$dialog.toast({
        mes: data.msg,
        timeout: 1500,
        icon: "none",
        callback: () => {
          // this.$router.go(-1);
        }
      });
    },

    // 客服
    async openchated() {
      try {
        let res = await ServicePhone();
        if (res.code == 0) {
          this.ServiceStatus = res.data.service_status; //开启
          this.ServiceMobile = res.data.service_mobile; //关闭
        }
      } catch (err) {
        throw err;
      }
    },
    //客服聊天
    async openChat() {
      // console.log(this.ServiceStatus);
      //   //判断后台是否开启客服链接
      if (this.ServiceStatus == 1) {
        var isSdkReady = false;
        ysf("onready", function() {
          isSdkReady = true;
        });
        // 判断 点击客服跳转到客服中心
        if (isSdkReady) {
          location.href = ysf("url");
        }
        let self = this;
        ysf("product", {
          show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
          title: self.detail.item_name || "壹企通",
          desc: self.detail.item_desc || "壹企通",
          picture: self.detail.base_pic || "壹企通",
          note: "壹企通",
          url: window.location.href,
          success: function() {
            // 成功回调
            if (isSdkReady) {
              ysf("open");
            }
          },
          error: function() {
            // 错误回调
            // handle error
          }
        });
      } else {
        this.sermob = true;
      }
    },
    cancel() {
      this.sermob = false;
      console.log(this.sermob);
    },

    // 商品评价
    async goodsEvaluate() {
      try {
        let data = await goodsEvaluate(this.item_id, 1, 3);
        if (data.code === 0) {
          this.evaluateList = data.data.data;
          this.evaluateTotal = data.data.total;
        }
      } catch (err) {
        throw err;
      }
    },
    // 更多评价
    moreEvaluate() {
      this.$router.push({
        path: "/comment_list",
        query: {
          item_id: this.item_id
        }
      });
    },
    //已售显隐
    salesStatus() {
      return (this.bid === 1131 || this.bid === 1134)
    },
    //处理已售
    getSales,
  },
  created() {
    //客服
    this.openchated();
    let address = this.default_address || {};
    this.activedAddress = address;
    this.$store.commit("setAddress", address);
    this.$store.commit("setGoodsInfo", {
      postage: "--",
      stock_name: "--"
    });
    // 获取商品详情
    this.getGoodsDetail();

    // 获取商品评价列表
    this.goodsEvaluate();
    document.title = "商品详情";
    // let stateObject = {};
    // let title = "title";
    // let newUrl = `${baseUrl}/#/goods-detail?item_id=${this.$route.query.item_id}`;
    // history.replaceState(stateObject, title, newUrl);
  },
  mounted() {
      this.$forceUpdate()
    if (JSON.stringify(this.default_address) !== "{}" && this.default_address) {
      this.edit_date = this.default_address;
      this.checkGood(this.default_address);
    }
  }
};
</script>
<style>

</style>
<style scoped lang="less">
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.goods-detail-scroll {
  height: 100vh;
  overflow: auto;
}
.goods-detail {
  padding-bottom: 1.1rem;
  box-sizing: border-box;
  background: #fff;

  .goods-info {
    background: #fff;
    padding: 0 0.3rem;
    box-sizing: border-box;

    .goods-intro {
      padding: 0.1rem 0 0.2rem;
      box-sizing: border-box;
      border-bottom: 1px solid #e1e1e1;

      .goods-name {
        font-size: 0.36rem;
        color: #333333;
        .ellipsis(4);
        line-height: 0.52rem;
      }

      .goods-tip {
        font-size: 0.28rem;
        color: #999999;
        line-height: 0.4rem;
      }

      .goods-price {
        display: flex;
        align-items: baseline;
        margin-top: 0.14rem;

        .group-title {
          width: 1.12rem;
          height: 0.4rem;
          line-height: 0.4rem;
          color: var(--main-color);
          font-size: 0.28rem;
          white-space: nowrap;
        }

        .real-price {
          color: #333333;
          margin-right: 0.16rem;

          span:nth-child(1) {
            .ft-weight;
            font-size: 0.36rem;
          }

          span:nth-child(2) {
            .ft-weight;
            font-size: 0.5rem;
          }
        }

        .s-price {
          font-size: 0.24rem;
          color: rgba(0, 0, 0, 0.38);
        }
      }

      .max-integral {
        margin-top: 0.14rem;
        color: var(--main-color);
      }
    }

    .goods-serve {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 0.7rem;
      border-bottom: 1px solid #e1e1e1;

      .serve-item {
        width: 33%;
        font-size: 0.22rem;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 5px;
        }
      }
    }

    .goods-buy {
      padding-top: 0.26rem;
      box-sizing: border-box;

      .buy-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        padding-bottom: 0.3rem;
        line-height: 0.4rem;

        .buy-title {
          color: #999999;
        }

        .buy-content {
          color: #333333;
          display: flex;
          align-items: center;
          max-width: 5rem;

          .active-tip {
            display: block;
            color: var(--main-color);
            padding: 0 5px;
            border: 1px solid var(--main-color);
            border-radius: 0.36rem;
            height: 0.36rem;
            display: flex;
            align-items: center;
          }

          .have-goods {
            color: var(--main-color);
          }

          .no-goods {
            color: rgba(153, 153, 153, 1);
          }

          img {
            height: 0.2rem;
            margin-left: 0.14rem;
          }
        }
      }
    }
  }

  .group-content {
    margin-bottom: 0.2rem;
  }

  .evaluate-content {
    width: 6.9rem;
    margin: 0 auto;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 0.38rem;

    .evaluate-title {
      padding: 0.2rem 0.2rem 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tip {
        font-size: 0.24rem;
        color: #666666;
      }

      .look-more {
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        color: var(--main-color);

        img {
          height: 0.2rem;
          margin-left: 5px;
        }
      }
    }
  }

  .goods-html {
    margin: 20px 0 0 0;
    .content-title {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 3.22rem;
      height: 0.54rem;
      border-radius: 0.54rem;
      background: rgba(108, 87, 255, 0.1);
      margin: 0 auto;
      padding: 0 0.25rem;
      box-sizing: border-box;

      .dian {
        width: 2px;
        height: 2px;
        background: #333333;
        border-radius: 2px;
      }

      .line {
        width: 0.56rem;
        height: 1px;
        background: #333333;
      }

      .text {
        font-size: 0.24rem;
        color: #333333;
      }
    }
  }

  .contents {
    /deep/ div {
      width: 100% !important;
    }

    /deep/ p {
      width: 100% !important;
    }

    /deep/ img {
      width: 100%;
      margin-bottom: -3px;
    }

    /deep/ table {
      width: 100%;
    }

    /deep/ img:last-child {
      width: 100%;
      /*margin-bottom: 1.5rem;*/
    }
  }

  .goods-bot {
    width: 100%;
    height: 1.1rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid #e1e1e1;

    .bot-icon {
      width: 0.6rem;
      margin-left: 0.3rem;
      text-align: center;

      img {
        width: 0.42rem;
        // height: 0.48rem;
        margin-bottom: 2px;
      }

      p {
        text-align: center;
        font-size: 0.2rem;
        line-height: 0.28rem;
        color: #333333;
        white-space: nowrap;
      }
    }
    .PopUp {
      // background-color: red;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;

      bottom: 0%;
      height: 2000%;
      .bot-mobile {
        width: 80%;
        // border: 1px solid #fff;
        position: absolute;
        height: 3rem;
        bottom: 4rem;
        left: 0.6rem;
        text-align: center;
        line-height: 80px;
        background-color: #fff;
        margin-left: 0.3rem;
        border-radius: 10px;
        img {
          width: 0.5rem;
          height: 0.5rem;
          float: right;
          margin: 0.2rem;
        }
        .service-phone {
          font-size: 19px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    margin-top: 0.5rem;
        }
        .service-phone span {
          color: #000;
        }
        .servicepnone {
          display: inline-block;
          // line-height: 4rem;
          width: 2rem;
          height: 0.5rem;
          font-size: 16px;
          color: #000;
          margin-top: 0.25rem;
        }
        .dial {
          left: 0rem;
          width: 1.5rem;
        }
        .dial,
        .cancel {
        position: absolute !important;
    font-size: 15px;
    border-radius: 0 0 0 10px;
    line-height: 0.8rem !important;
    height: 0.8rem;
    width: 3rem;
    // border: 1px solid #ccc;
    line-height: 0.5rem;
    text-align: center;
    color: #fff;
    top: 2.2rem !important;
    background: var(--main-color);
        }
        .cancel {
          left: 50% !important;
          width: 50%;
          border-radius: 0 0  10px 0;
          background:#f1f1f1;
          color: var(--main-color)
        }
      }
    }
    .buy-btn {
      height: 0.8rem;
      border-radius: 0.8rem;
      border: 1px solid var(--main-color);
      display: flex;
      margin-left: 0.24rem;
      overflow: hidden;
      box-sizing: border-box;

      .add-car,
      .buy-now {
        width: 2.2rem;
        height: 0.8rem;
        text-align: center;
        font-size: 0.32rem;
        line-height: 0.8rem;
      }

      .add-car,
      .self-buy {
        color: var(--main-color);
      }

      .buy-now,
      .group-buy {
        color: #ffffff;
        background: var(--main-color);
      }

      .self-buy,
      .group-buy {
        width: 2.2rem;
        height: 0.8rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p:nth-child(1) {
          font-size: 0.24rem;
        }

        p:nth-child(2) {
          font-size: 0.26rem;
          line-height: 0.36rem;
        }
      }
    }

    .no-goods {
      width: 4.8rem;
      height: 0.8rem;
      background: #999999;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.32rem;
      color: #fff;
      border: 1px solid #999999;
      box-sizing: border-box;
      border-radius: 0.8rem;
      margin-left: 0.36rem;
    }
  }

  .ft-weight {
    font-weight: bold;
  }
}
</style>
