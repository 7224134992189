var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "goods-detail-scroll" }, [
    _c(
      "div",
      { staticClass: "goods-detail" },
      [
        _c("banner", {
          staticStyle: { "z-index": "0" },
          attrs: { banners: _vm.banners },
          model: {
            value: _vm.detail.is_collect,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "is_collect", $$v)
            },
            expression: "detail.is_collect",
          },
        }),
        _vm.goodsType == 1 || _vm.goodsType == 2
          ? _c("flash-sale", {
              attrs: {
                goodsType: _vm.goodsType,
                detail: _vm.detail,
                goodsPrice: _vm.goodsPrice,
                businessType: _vm.businessType,
                showWay: _vm.showWay,
                integralRate: _vm.integralRate,
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "goods-info" }, [
          _c("div", { staticClass: "goods-intro" }, [
            _c("p", { staticClass: "goods-name ft-weight" }, [
              _vm._v(_vm._s(_vm.detail.item_name)),
            ]),
            _c("p", { staticClass: "goods-tip" }, [
              _vm._v(_vm._s(_vm.detail.item_desc)),
            ]),
          ]),
        ]),
        _vm.goodsType == 3 && _vm.groupList.length > 0
          ? _c(
              "div",
              { staticClass: "group-content" },
              [
                _c("group-list", {
                  attrs: {
                    moreGroup: true,
                    groupList: _vm.groupList,
                    group_buy_item_id: _vm.detail.group_buy_item_info.id,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.evaluateTotal > 0
          ? _c(
              "div",
              { staticClass: "evaluate-content" },
              [
                _c(
                  "div",
                  {
                    staticClass: "evaluate-title",
                    on: {
                      click: function ($event) {
                        return _vm.moreEvaluate()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("商品评价（" + _vm._s(_vm.evaluateTotal) + "）"),
                    ]),
                    _vm._m(0),
                  ]
                ),
                _c("evaluate-list", { attrs: { list: _vm.evaluateList } }),
              ],
              1
            )
          : _vm._e(),
        _vm._m(1),
        _c("div", {
          staticClass: "contents",
          domProps: { innerHTML: _vm._s(_vm.item_txt.contents) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "look-more" }, [
      _c("span", [_vm._v("查看全部")]),
      _c("img", {
        attrs: { src: require("@/assets/icon_more_light@2x.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "goods-html" }, [
      _c("div", { staticClass: "content-title" }, [
        _c("span", { staticClass: "dian" }),
        _c("span", { staticClass: "line" }),
        _c("span", { staticClass: "text" }, [_vm._v("商品详情")]),
        _c("span", { staticClass: "line" }),
        _c("span", { staticClass: "dian" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }